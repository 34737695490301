<template>
  <v-card class="card" elevation="0">

    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"

      @click:row="handleClick">
      class="elevation-1 table">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
            ></v-text-field
            ></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2"
                     v-if="$store.getters.hasCreatePermission('device')"
                     outlined
                     color="indigo" v-bind="attrs" v-on="on"
                    @click="qrDialog = false"
              >
                اضافة {{buttonName}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
               هل تريد اضافة جهاز جديد؟
              </v-card-title>
              <v-card-text>سوف يتم ادخال رقم الهاتف وباقي البيانات تلقائي عند ربط الجهاز بالواتساب.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  الغاء
                </v-btn>

                <v-btn

                    color="green darken-1"
                    @click="openDialogNew()"
                    text>
                  التالي

                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <CreateDevice  v-if="isDialog" :is-dialog.sync="isDialog" :is-update="false" :content="{}"/>
          <WhatsappQr  v-if="qrDialog" :qr-dialog.sync="qrDialog" :itemSelected="itemSelected"/>



        </v-toolbar>
      </template>

      <template v-slot:item.active="{ item }">
        <v-chip
            :color="getColor(item.active)"
            dark
        >
          {{ item.active?"فعال":("متوقف") }}
        </v-chip>
      </template>
      <template v-slot:item.quota="{ item }">
        {{item.quota_type=='unlimited'?'مفتوح':item.quota}}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
            :color="getColor(item.status)"
            dark
        >
          {{ item.status=="connected"?"متصل":"غير متصل"}}
        </v-chip>
      </template>
    </v-data-table>

  </v-card>
</template>
<script>
import WhatsappQr from "@/components/dialogs/WhatsappQr";
import CreateDevice from "@/components/createNewDevice";
export default {
  components:{
    WhatsappQr,
    CreateDevice
  },
  props: ['buttonName',"desserts"],

  data: () => ({
    search: "",
    qrDialog: false,
    isDialog:false,
    itemSelected:null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,

    headers:[
      {
        text: 'الحساب',
        align: 'start',
        value: 'userid',
      },
      {
        text: 'اسم الجهاز',
        value: 'name',
      },
      { text: 'رقم الوتساب', value: 'phone' },
      { text: 'تاريخ انتهاء الباقة', value: 'expiredDate' },
      { text: 'الرصيد', value: 'quota' },
      { text: 'حالة الجهاز', value: 'active' },
      { text: 'حالة الربط', value: 'status' },

    ],

  }),
  methods: {

    openDialogNew(){

      this.dialog =false;
      this.isDialog= true;

    },

    async handleClick(value){

      this.itemSelected = value;
      this.qrDialog = true;

    },
    getColor(value){

      if (value==true||value=="connected") return 'green'
      else if (value=="disconnected") return 'orange'
      else return 'red'
    },

  },

};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(202, 202, 202, 0.986);
}
.table {
  padding: 10px;

}

</style>
