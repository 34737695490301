<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري ارسال الرسالة"

    />

      <v-card elevation="0">
        <v-card-title>
          <span class="text-h5">ارسال رسالة نص</span>
        </v-card-title>
        <v-card-text>

          <v-container >
            <v-row >
              <v-col>
                <v-card ref="form" elevation="0">
                  <v-card-text>
                    <v-form v-model="isFormValid">

                      <v-select
                          ref="inputRef"
                          dense
                          filled
                          no-data-text="الاجهزة غير متصلة او الرصيد غير كافي "
                          outlined
                          label="الجهاز"
                          solo
                          v-model="select"
                          @input="setSelected"
                          hide-selected
                          :rules="[() => !!select || 'يجب اختيار الجهاز']"

                          :items="itemsDevices"
                          clearable
                          item-value="token"
                          :item-text="item => item.number+' ::: '+item.name"
                      ></v-select>
                      <v-text-field
                          ref="inputRef"
                          outlined
                          dense
                          type="number"
                          filled
                          color="rgb(200, 200, 200)"
                          label="الرقم"
                          v-model="destination"
                          :rules="[() => !!destination || 'يجب ادخال رقم الوجهة']"
                          value=""
                          class="input-group--focused"
                      ></v-text-field>
                      <v-file-input
                          outlined
                          dense
                          label="قم بختيار الملف"
                          ref="inputRef"
                          @change="Preview_image"
                          v-model="image"
                      >
                      </v-file-input>
                      <v-img :src="url" width="200" class="pa-5 mb-9"></v-img>
                      <v-textarea
                          ref="inputRef"
                          outlined
                          dense
                          filled
                          color="rgb(200, 200, 200)"
                          label="الرسالة"
                          v-model="plantext"
                          value=""
                          :rules="[() => !!plantext || 'يجب ادخال الرسالة']"
                          required
                          class="input-group--focused"
                      ></v-textarea>
                    </v-form>

                  </v-card-text>
                </v-card>

                <v-row>

                  <v-spacer></v-spacer>
                  <v-btn class="ma-2"
                         width="20%"
                         outlined
                         color="green"
                         :disabled="!isFormValid"
                         @click="sendMessage()"
                  >
                    ارسال
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>


      </v-card>
      <v-alert
          class="mt-5"
          left
          border="left"
          color="red"
          dismissible
          elevation="9"
          type="error"
          v-model="isShow"
      >{{msgError}}</v-alert>
      <SuccessDialog title="تم ارسال الرسالة بنجاح!"  v-if="successDialog" :success-dialog.sync="successDialog" />



  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';

export default {
  components: {
    Loading,
    SuccessDialog,

  },
  props:[
    "itemsDevices"
  ],
  data () {
    return {
      isFormValid: false,
      select: "",

      progress:0,
      url: null,
      image: null,
      selectToken: "",
      isloading: false,
      dialog: false,
      plantext:"",
      device:"",
      destination:"",
      isShow: false,
      successDialog: false,
      msgSucess:"تم ارسال الرسالة بنجاح بنحاح",
      msgError:"فشل في الارسال",

    }
  },

  methods: {
    Preview_image() {
      this.url= URL.createObjectURL(this.image)
    },
    setSelected(value){

      this.selectToken= value;
    },
    async sendMessage() {
      try {

        this.isloading =true;

        const messagebody = {
          'message': this.plantext,
          'number': this.destination,
          "type":"media",
          "file":this.image,
        };

        this.progress = 0;
        const response = await PushServicesSocket.sendMediaWhatsapp(messagebody,(event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }, this.selectToken);
        if(response.status)
        {
          this.successDialog = true;
          this.isloading = false;
          this.isShow = false;
          this.$refs.inputRef.reset()
        }else{
          this.msgError = response.message;
          this.isShow = true;
          this.isloading =false;
        }
      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    }

  },
  async mounted() {

  }
}
</script>

<style scoped>


</style>