<template>
  <div>

    <v-row justify="center">
      <v-dialog
          v-model="qrDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >

        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click.native="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title >ربط الواتساب</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn
                v-if="$store.getters.hasDeletePermission('device')"
                icon
                color="blue"
                class="px-10"
                @click="deleteItem()"
            >
              <v-icon size="30" color="red" class="ma-5">mdi-delete</v-icon>
            </v-btn>
            <v-btn
                v-if="$store.getters.hasChangePermission('device')"
                icon
                color="blue"
                class="px-10"
                @click="updateItem()"
            >
              <v-icon size="30" color="white" class="ma-5" >mdi-pencil</v-icon>

            </v-btn>
          </v-toolbar>
          <Loading v-if="isloading"/>
          <v-container v-else>
            <v-container >




              <v-row justify="space-around" >

                <VCol  >
                  <v-card elevation="2" class="pa-3 ma-2 justify-center align-center" align="center" height="65vh" >
                    <div class="font-weight-bold ml-8 mb-2 " style="font-size: 1.5rem">
                      ربط الجهاز
                    </div>

                    <v-card-text  >

                      <img :src="qrcodescr" alt="كود الواتساب" id="qrcode" v-if="qrcode&&!isErrorQr">
                      <img :src="require('@/assets/icons/sucess.png')" width="300" v-else-if="isAready&&!isQrLoading">
                      <div class="loading">
                        <v-progress-circular

                            v-if="isQrLoading"
                            :size="40"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                      </div>



                    </v-card-text>

                    <v-card-actions button>

                      <v-btn
                          v-if="!qrcode&&!isQrLoading"
                          absolute
                          bottom

                          color="blue"
                          class="pa-10 "
                          @click="generateQr()"
                      >
                        Generate Qr
                      </v-btn>

                    </v-card-actions>
                  </v-card>
                </VCol>
                <VCol  >
                  <v-card elevation="2" class="pa-3 ma-2" align="center">


                    <v-card-text  >
                      <div class="font-weight-bold ml-8 mb-2 " style="font-size: 1.5rem">
                        بيانات الجهاز
                      </div>

                      <v-timeline
                          align-top
                          dense
                      >
                        <v-timeline-item
                            v-for="message in messages"
                            :key="message.time"
                            :color="message.color"
                            small
                        >
                          <div style="font-size: 1.1rem; text-align: right">
                            <div class="font-weight-normal" >
                              <strong >{{ message.title }}</strong>
                            </div>

                            <div class="blue--text text--darken-2">{{ message.value }}</div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                    </v-card-text>

                    <v-card-actions >
                      <v-row

                          justify="end"
                      >


                      </v-row>

                    </v-card-actions>
                  </v-card>
                </VCol>

                <VCol cols="12" md="12"
                      sm="12"
                      xl="12" >
                  <v-card elevation="2" class="pa-3 ma-2" align="center">

                    <v-progress-circular
                        style="height: 70vh"

                        v-if="isloading"
                        :size="40"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <v-card-text v-else>
                      <div class="font-weight-bold ml-8 mb-2 " style="font-size: 1.5rem">
                        تفاصيل الربط
                      </div>

                      <v-timeline
                          align-top
                          dense
                      >
                        <v-timeline-item
                            v-for="message in messagesConnect"
                            :key="message.time"
                            :color="message.color"
                            small
                        >
                          <div style="font-size: 1.1rem; text-align: right">
                            <div class="font-weight-normal" >
                              <strong >{{ message.title }}</strong>
                            </div>
                            <v-text-field readonly :value="message.value"
                                          solo
                            ></v-text-field>

                          </div>
                        </v-timeline-item>
                      </v-timeline>

                    </v-card-text>

                    <v-card-actions >
                      <v-row

                          justify="end"
                      >

                        <v-btn
                            icon
                            color="blue"
                            class="pa-10"
                            @click="refreshAll()"

                        >
                          <v-icon size="40px">mdi-cached</v-icon>
                        </v-btn>
                      </v-row>

                    </v-card-actions>
                  </v-card>
                </VCol>

              </v-row>

            </v-container>
          </v-container>
        </v-card>
        <CreateDevice  v-if="isDialog" :is-dialog.sync="isDialog" :is-update="true" :content="itemSelected"/>
        <DeleteItemDialog :url-name="'/device/delete'" :delete-controller.sync="deleteController" :item-delete="itemSelected" :token="$store.state.token"/>

      </v-dialog>
    </v-row>
  </div>

</template>

<script>

import Loading from '@/components/Loading.vue';
import PushServicesSocket from "@/services/PushServicesSocket";
import ServiceManager from "@/services/ServicesManager";
import { io } from "socket.io-client";

import {urlWS} from "@/configuration/dbConfig";
import DeleteItemDialog from "@/components/dialogs/DeleteItemDialog";
import CreateDevice from "@/components/createNewDevice";



export default {
  props:{
    itemSelected:{},
    qrDialog:Boolean

  },
  components: {
    Loading,
    DeleteItemDialog,
    CreateDevice


  },
  data () {
    return {
      isDialog:false,
      isAready:false,
      isErrorQr:false,
      isQrLoading:true,
      logsItems:[],
      isFristGen:true,
      deleteController:false,
      socket:null,
      itemTemp:{},
      qrcodescr:"",
      qrcode:false,
      messages: {
        sender:{
          title: "رقم الوتس",
          value: "",
          color: "blue"
        },
        quota:{
          title: "الرصيد المتبقي",
          value: "",
          color: "blue"
        },
        expired_date:{
          title: "تاريخ انتهاء الباقة",
          value: "",
          color: "green"
        },
        active:{
          title: "حالة الحساب",
          value: "",
          color: "green"
        },
        status:{
          title: "حالة الربط",
          value: "",
          color: "green"
        },
      },
      messagesConnect: {
        name:{
          title: "Device Name",
          value: "",
          color: "red"
        },
        serial:{
          title: "Device serial",
          value: "",
          color: "red"
        },
        token:{
          title: "Key api token",
          value: "",
          color: "red"
        },
        authW:{
          title: "حالة اتصال الوتس",
          value: "",
          color: "red"
        },
        relay:{
          title: "Webhook Relay",
          value: "",
          color: "red"
        },



      },
      whatsappItems: {},
      isloading: false,

    }
  },
  methods: {
    deleteItem(){
      this.itemSelected.id = this.itemSelected.serial;
      this.deleteController = true;
    },
    updateItem(){

      this.isDialog = true;
    },

    async generateQr(){
      this.isErrorQr=false;
      this.isQrLoading=true;

      let response = await PushServicesSocket.GetDataCustom(`/api/device/qr-code`,this.itemSelected.token);

      if(response.message=="ready"){
        this.onRedy(response);
      }
      else if(response.message=="qr"){
        this.onQr(response);
      }
      else{
        this.onQrError(response);
      }

    },

    async refreshAll(){
      this.isloading = true;
      this.isQrLoading=true;

      if(this.itemSelected){
        this.itemTemp = await PushServicesSocket.GetData("/device/get/"+this.itemSelected.serial);
        if(this.itemTemp.status){
          this.itemTemp = this.itemTemp.data;
          let expired_date = new Date();
          expired_date.setTime(parseInt(this.itemTemp.expired_date));
          this.itemSelected={
            name:  this.itemTemp.name,
            serial:  this.itemTemp.serial,
            phone:  this.itemTemp.number,
            expiredDate:  this.itemTemp.expired_date?expired_date.toISOString():"",
            quota:  this.itemTemp.quota,
            active:  this.itemTemp.active,
            status:  this.itemTemp.status,
            token:  this.itemTemp.token,
            relay:  this.itemTemp.relay
          }
        }

      }

      await this.refreshInfoDevise();
      await this.refreshConnectSocket();
      this.isQrLoading=false;
      this.isloading = false;
    },
    async refreshInfoDevise (){
      this.isloading = true;
      if(this.itemSelected){
        let expiredDate = new Date(this.itemSelected.expiredDate);

        this.messages.quota.value = this.itemSelected.quota;
        this.messages.quota.color =  (! ServiceManager.isExpiredWhatsapp(expiredDate.getTime())||this.itemSelected.quota=="0")?"red": ("green");

        this.messages.sender.value = this.itemSelected.phone??"لا يوجد";
        this.messages.sender.color = this.itemSelected.status=="disconnected"?"red":"green";


        this.messages.expired_date.value = ServiceManager.isExpiredWhatsapp(expiredDate.getTime())?this.itemSelected.expiredDate:"\n  الباقة منتهية  "+this.itemSelected.expiredDate;

        this.messages.expired_date.color =   ServiceManager.isExpiredWhatsapp(expiredDate.getTime())?"green":"red";
        this.messages.status.value = this.itemSelected.status=="connected"?"فعال":"غير فعال";


        this.isAready = this.itemSelected.status=="connected";

        this.messages.status.color = this.itemSelected.status=="disconnected"?"red":"green";

        this.messages.active.value = this.itemSelected.active?"فعال":"غير فعال";
        this.messages.active.color =this.itemSelected.status=="disconnected"?"red":"green";

      }

      this.isloading = false;
    },
    async refreshConnectSocket (){

      this.isloading = true;

      if(this.itemSelected){

        this.messagesConnect.serial.value = this.itemSelected.serial;
        this.messagesConnect.serial.color = this.itemSelected.status=="disconnected"?"red":"green";
        this.messagesConnect.name.value = this.itemSelected.name;
        this.messagesConnect.name.color = this.itemSelected.status=="disconnected"?"red":"green";
        this.messagesConnect.token.value = this.itemSelected.token;
        this.messagesConnect.token.color = this.itemSelected.status=="disconnected"?"red":"green";
        this.messagesConnect.authW.value = this.itemSelected.status;
        this.messagesConnect.authW.color = this.itemSelected.status=="disconnected"?"red":"green";
        this.messagesConnect.relay.value = this.itemSelected.relay;
        try{
          this.messagesConnect.relay.color = this.itemSelected.relay.startsWith("http")?"green":"red";
        }catch (e){

        }





      }


      this.isloading = false;
    },
    close() {
      this.$emit('update:qrDialog', false);
    },
    onMessage(date) {
      this.logsItems.push(date.text);
    },
    onRefresh(date) {
      this.refreshAll();
    },
    onQr(data) {
      this.isQrLoading=false;
      this.isAready=false;
      this.qrcodescr = data.src;
      this.qrcode =true;

      setTimeout(() => {
        this.onQrError({});

      }, this.socket?40000:2000);

    },
    async onQrError(data) {

      this.isQrLoading=false;
      this.qrcode =false;
      this.isAready=false;
      this.isErrorQr=true;
      this.refreshAll();

    },
    onRedy(data) {
      this.qrcode = false;
      this.isQrLoading=false;
      this.isAready=true;
      this.logsItems.push("تمت المصادقة بنجاح ..");
      this.refreshAll();
    }
  },

  async created(){
    this.refreshAll();
    if(!this.socket&&this.itemSelected.token)
      this.socket =  io(urlWS, {
        withCredentials: false,
        extraHeaders: {
          "authorization":"Bearer " +this.itemSelected.token
        }
      });

    this.socket.on('open', (event) => {
      console.log(event)

      this.socket.send('Hello');
    });



    this.socket.on('refrech', this.onRefresh);
    this.socket.on('message', this.onMessage);

    this.socket.on('qr', this.onQr);
    this.socket.on('error_qr', this.onQrError);


    this.socket.on('authenticated', this.onRedy);
    this.socket.on('ready', this.onRedy);
  }

}
</script>

<style scoped>
#qrcode {

  width: 50vh;
  border: 1px solid #efefef;
  border-radius: 4px;
}
ul.logs {
  max-height: 150px;
  padding: 15px 15px 15px 30px;
  margin-top: 5px;
  border-radius: 4px;
  overflow-y: auto;
  background: #efefef;
  color: #666;
  font-size: 18px;
  text-decoration: none;
}
ul.logs li:first-child {
  color: green;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40vh;
  width: 100%;
  font-size: 40px;
  color: rgb(167, 167, 167);
  font-weight: 500;
}
</style>