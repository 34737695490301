<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري ارسال الرسالة"

    />

      <v-card elevation="0">
        <v-card-title>
          <span class="text-h5">ارسال رسالة نص</span>
        </v-card-title>
        <v-card-text>
          <v-container >
            <v-row >
              <v-col>
                <v-card ref="form" elevation="0">
                  <v-card-text>
                    <v-form v-model="isFormValid">

                      <v-select
                          ref="inputRef"
                          dense
                          filled
                          no-data-text="الاجهزة غير متصلة او الرصيد غير كافي "
                          outlined
                          label="الجهاز"
                          solo
                          v-model="select"
                          @input="setSelected"
                          hide-selected
                          :rules="[() => !!select || 'يجب اختيار الجهاز']"
                          :items="itemsDevices"
                          clearable
                          item-value="token"
                          :item-text="item => item.number+' ::: '+item.name"
                      ></v-select>

                      <v-select
                          ref="inputRef"


                          no-data-text="الجهاز غير متصل او لايوجد جهات اتصال "
                          outlined
                          label="اختار جهات الاتصال"
                          solo
                          v-model="contactsSelected"
                          :items="contactsItems.data"
                          clearable
                          chips
                          :item-text="item => item.name||item.pushname||item.number"
                          item-value="number"

                          multiple
                      >

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="chooseAll"
                          >
                            <v-list-item-action>
                              <v-icon :color="isChooseSome > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                اختيار الكل
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>


                      </v-select>
                      <v-text-field

                          ref="inputRef"
                          outlined
                          dense
                          type="text"
                          filled
                          placeholder="96777777 ,  96777777  ,  96777777"
                          hint=" ضع فاصلة بين الارقام 96777777 ,  96777777  ,  96777777  "
                          color="rgb(200, 200, 200)"
                          label="ارقام الوجهة"
                          v-model="destination"

                          value=""
                          class="input-group--focused"
                      ></v-text-field>
                      <v-file-input
                          ref="inputRef"
                          outlined
                          dense
                          chips
                          label="قم بختيار الملف"
                          @change="Preview_image"
                          v-model="image"
                          clearable
                          @click:clear="url=null"
                      >
                      </v-file-input>
                      <v-img :src="url" width="200" class="pa-5 mb-9" v-if="url" ></v-img>
                      <v-textarea
                          ref="inputRef"
                          outlined
                          dense
                          filled
                          color="rgb(200, 200, 200)"
                          label="الرسالة"
                          v-model="plantext"
                          value=""
                          :rules="[() => !!plantext || 'يجب ادخال الرسالة']"
                          required
                          class="input-group--focused"
                      ></v-textarea>
                      <v-alert

                          left
                          border="left"
                          color="red"
                          dismissible
                          elevation="9"
                          type="error"
                          v-model="isShow"
                      >{{msgError}}</v-alert>
                      <v-alert

                          left
                          border="left"
                          color="green"
                          dismissible
                          elevation="9"
                          type="success"
                          v-model="successDialog"
                      >"تم ارسال الرسالة بنجاح!"</v-alert>
                    </v-form>

                  </v-card-text>
                </v-card>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2"
                         width="20%"
                         outlined
                         color="green"
                         :disabled="!isFormValid"
                         @click="sendMessage()"
                  >
                    ارسال
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>


      </v-card>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';

export default {
  components: {
    Loading,
    SuccessDialog

  },
  props:[
    "itemsDevices"
  ],
  data () {
    return {

      rules: [
          destination =>  ( this.destination.length > 0 ) || 'يجب ادخل ارقام الوجهه'
      ],
      isFormValid: false,
      isChooseFromContacts:false,
      select: "",
      selectToken: "",
      contactsSelected:[],
      contactsItems:[],
      isloading: false,
      dialog: false,
      plantext:"",
      device:"",
      destination:"",
      isShow: false,
      successDialog: false,
      msgSucess:"تم ارسال الرسالة بنجاح بنحاح",
      msgError:"فشل في الارسال",
      url: null,
      image: null,
    }
  },

  methods: {

    Preview_image() {
      this.url= URL.createObjectURL(this.image)
    },
    chooseAll () {
      if (this.isChooseAll) {
        this.contactsSelected = [];
      } else {
        this.contactsSelected = this.contactsItems.data.slice();
      }
    },

    async setSelected(value){

      this.selectToken= value;

      await this.getContacts(value);

    },
    async getContacts(token){
      this.contactsItems = await PushServicesSocket.GetDataDevice("/contacts",token);
    },
    async sendMedia(destination) {
      try {

        this.isloading =true;

        const messagebody = {
          'message': this.plantext,
          'number': destination,
          "type":"media",
          "file":this.image,
        };
      console.log(this.image)
        this.progress = 0;
        const response = await PushServicesSocket.sendMediaWhatsapp(messagebody,(event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }, this.selectToken);
        if(response.status)
        {
          this.successDialog = true;
          this.isloading = false;
          this.isShow = false;

        }else{
          this.msgError = response.message??this.msgError;
          this.isShow = true;
          this.isloading =false;
        }
      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    },
    async sendMessageText(destination) {

      try {

        this.isloading =true;
        const messagebody = {
          'message': this.plantext,
          'number': destination,
        };


        const response = await PushServicesSocket.sendTextWhatsapp(messagebody, this.selectToken);

        if(response.status)
        {
          this.successDialog = true;
          this.isloading = false;
          this.isShow = false;

        }else{

          this.msgError = response.message??this.msgError;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {

        this.isShow = true;
        this.isloading =false;
      }
    },
    async sendMessage() {
      let destinations =[];
      destinations.push(...(this.contactsSelected));
      destinations.push(...(this.destination.split(",")));


      if(this.image){
        destinations.forEach(async value => {
          await this.sendMedia(value);
        });

      }
      else
      {
        destinations.forEach(async value => {
          await this.sendMessageText(value);
        });

      }

    }

  },
  computed:{
    isChooseAll () {
      return this.contactsSelected?.length??0 === this.contactsItems.data.length
    },
    isChooseSome () {
      return this.contactsSelected?.length??0 > 0
    },
    icon () {
      if (this.isChooseAll) return 'mdi-close-box'
      if (this.isChooseSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },


  }
}
</script>

<style scoped>


</style>