<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" :title="isUpdate?'جاري تعديل الجهاز':'جاري انشاء جهاز جديد'"

    />

      <v-dialog
          width="60%"
          v-model="isDialog">

        <v-card elevation="0">
          <v-card-title>
            <span class="text-h5">{{isUpdate?'تعديل الجهاز':'انشاء جهاز جديد'}}</span>
          </v-card-title>
          <v-card-text>

            <v-container >
              <v-row >
                <v-col>
                  <v-card ref="form" elevation="0">
                    <v-card-text>
                      <v-form v-model="isFormValid">


                        <v-text-field
                            ref="inputRef"
                            outlined
                            dense
                            :rules="[() => !!nameModal || 'يجب ادخال الاسم']"
                            filled
                            color="rgb(200, 200, 200)"
                            label="اسم الجهاز"
                            v-model="nameModal"
                            value=""
                            class="input-group--focused"
                        ></v-text-field>

                        <v-text-field

                            ref="inputRef"
                            outlined
                            dense

                            filled
                            color="rgb(200, 200, 200)"
                            label="رابط اعادة توجية الرسائل الواردة"
                            v-model="destination"
                            value=""
                            class="input-group--focused"
                        ></v-text-field>
                        <v-col cols="12" md="6" sm="12" xs="12" v-if="isUpdate">
                          <v-switch
                              v-model="switchItems"
                              label="تفعيل الجهاز"
                              color="success"
                              value="is_active"
                              hide-details
                          ></v-switch>
                        </v-col>    <v-col cols="12" md="6" sm="12" xs="12" v-if="isUpdate">
                          <v-switch
                              v-model="switchItems"
                              label="تغيير Api Key"
                              color="success"
                              value="token"
                              hide-details
                          ></v-switch>
                        </v-col>
                      </v-form>

                    </v-card-text>
                  </v-card>
                  <v-row>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="paink"

                           @click="close"
                    >
                      الغاء
                    </v-btn>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="green"
                           :disabled="!isFormValid"
                           @click="createDevice()"
                    >
                      {{isUpdate?'تعديل':'انشاء'}}
                    </v-btn>

                  </v-row>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>


        </v-card>
        <v-alert
            class="mt-5"
            left
            border="left"
            color="red"
            dismissible
            elevation="9"
            type="error"
            v-model="isShow"
        >{{msgError}}</v-alert>
        <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
      </v-dialog>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';
import store from "@/store";
import Base64 from "@/services/Base64";

export default {
  components: {
    Loading,
    SuccessDialog

  },
  props:{
    isUpdate: {
      default: false
    },
    isDialog: {
      default: false
    },
    content:{}
  },

  data () {
    return {
      isFormValid: false,

      isloading: false,

      nameModal:"",
      destination:"",
      isShow: false,
      switchItems:[],
      successDialog: false,
      msgSucess:"تم انشاء الجهاز بنجاح",
      msgError:"فشل ",

    }
  },

  methods: {

    close() {
      this.$emit('update:isDialog', false);
    },
    async createDevice() {

      try {

        this.isloading =true;


        const body = {
          'relay': this.destination,
          'name': this.nameModal,
          'token':this.switchItems.find(value => value=='token')=='token',
          'active':this.switchItems.find(value => value=='is_active')=='is_active',
        };
        let response;
        if(!this.isUpdate)
         response = await PushServicesSocket.createDevice(body);
        else
         response = await PushServicesSocket.PutUpdateData("/device/update/"+this.content.serial,body);

        if(response.status)
        {

          this.msgSucess= (this.isUpdate? response.message:this.msgSucess+"  "+ response.data.serial);
          this.successDialog = true;
          this.isloading = false;

          setTimeout(() => {
                this.close();
            this.$root.$emit('updateDeviceTable');
              },3000);

        }else{
          this.msgError = response.message;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    },
     initializeUpdate(){

     this.destination=this.content.relay;
     this.nameModal = this.content.name;
     if(this.content.active===true)
       this.switchItems.push('is_active');

    }
  },
  async mounted() {
    if(this.isUpdate)
    this.initializeUpdate();

  }
}
</script>

<style scoped>


</style>