<template>

  <v-tabs
      color="deep-purple accent-4"
      centered
  >

    <v-tab  v-if="$store.getters.hasViewPermission('device')">الاجهزة</v-tab>
    <v-tab>الرسائل</v-tab>


    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
            الاجهزة
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getAllDevice()"

          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>

        <DeviceTable button-name="جهاز جديد" :desserts="desserts" />
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <v-container >

        <SendText :items-devices="getDevices"/>
      </v-container>
    </v-tab-item>

  </v-tabs>


</template>

<script>
import Loading from '@/components/Loading.vue';
import PushServicesSocket from "@/services/PushServicesSocket";
import ServicesManager from "@/services/ServicesManager";
import DeviceTable from '@/components/DeviceTable';
import SendText from '@/components/SendMessageText';
import SendMedia from '@/components/SendMessageMedia';
    export default {
      components: {
        Loading,
        SendMedia,
        DeviceTable,
        SendText
      },
      data: () =>({
        devicesItems:[],
        desserts:[

        ],

        isloading: false,

      }),
      methods:{


      async getAllDevice(){
        this.isloading = true;
        this. devicesItems = await PushServicesSocket.GetData("/device/getall");
        this.desserts=[];
        if( this.devicesItems.status)
        {
          this.devicesItems = this.devicesItems.data;
          this.devicesItems.forEach(item => {
            let expired_date ="";
            if(item.expired_date){
              expired_date = new Date();
              expired_date.setTime(parseInt(item.expired_date));
              expired_date =  expired_date.toISOString().substring(0,10);
            }
            this.desserts.push(
                {
                  name: item.name,
                  serial: item.serial,
                  phone: item.number,
                  expiredDate:expired_date,
                  quota: item.quota,
                  quota_type:item.quota_type,
                  active: item.active,
                  status: item.status,
                  token: item.token,
                  userid:item.userid

                }
            );
          });
        }

        this.isloading = false;
      },


      },
      computed:{
         getDevices (){
          return this.devicesItems.filter(value =>ServicesManager.isAradyDevice(value));
        }

      },
     async mounted() {
       this.$root.$on('updateDeviceTable', async() => {

         await this.getAllDevice();
       });
       if(this.$store.getters.hasViewPermission('device'))
        await this.getAllDevice();
      },

    }
</script>

<style scoped>
.Whatsapp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 40px;
    color: rgb(167, 167, 167);
    font-weight: 600;
}
</style>



